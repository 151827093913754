import React, { createContext, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useResolvedPath, useParams } from 'react-router-dom';
import Axios from 'axios';

import CRMDashboard from 'pages/CRM/containers/CRMDashboard';
import languageMap from 'Language/Language';

import ioCRM from 'services/ioCRM';
import { generateAuthToken } from 'services/auth';
import processWSEvents from 'services/crmWSEvents';

import { usePrevious } from 'utils/utils';
import {
  BOT,
  DEPARTMENTS_MANAGEMENT,
  GET_FILTERS_ENDPOINT,
  MENU_ANNOTATIONS,
  MENU_CHANGE_LOGS,
  MENU_COLLECTIONS,
  MENU_FEEDBACK,
  MENU_FLOWS,
  MENU_GENERATIVE_AI,
  MENU_INTEGRATIONS,
  MENU_OVERVIEW,
  MENU_QNA,
  MENU_TEAM_PERFORMANCE,
  MENU_WISE_AGENT,
  PROFILE,
  PROJECT_SETTINGS,
  REPORTS,
  SETTINGS,
  USERS_MANAGEMENT,
} from 'utils/constants/index';

import { setToken } from 'store/auth/actions';
import {
  addToExternalAllUnread,
  addToInternalAllUnread,
  addToUnassignedUnread,
  addToYouUnread,
  decrementAll,
  decrementInternalAll,
  decrementUnassigned,
  decrementYou,
  getAgents,
  getChatHistory,
  getConversations,
  getDepartments,
  incrementAll,
  incrementInternalAll,
  incrementUnassigned,
  incrementYou,
  refreshConversationChannel,
  removeConversation,
  removeFromExternalAllUnread,
  removeFromInternalAllUnread,
  removeFromUnassignedUnread,
  removeFromYouUnread,
  setBotTab,
  setConversationReadStatus,
  setCurrentConversation,
  setFilters,
  setInboxFolder,
  setMainMenu,
  setNewConversation,
  setReportsTab,
  setSubMenu,
  setToConversationHistory,
  updateConversationInList,
  updateLastMessage,
  updateMessageTimestamp,
} from 'store/crm/actions';

import {
  selectConversations,
  selectConversationsStatusFilter,
  selectCurrentConversation,
  selectCurrentUser,
  selectDepartmentsIDs,
  selectInboxFolder,
  selectIsMobile,
  selectMainMenu,
} from 'store/crm/selectors';

import './CRM.scss';
import useComponentDidMount from '../../utils/useComponentDidMount';

export const CrmFiltersContext = createContext({});

const subMenuToMenu = {
  [MENU_ANNOTATIONS]: BOT,
  [MENU_COLLECTIONS]: BOT,
  [MENU_FLOWS]: BOT,
  [MENU_QNA]: BOT,
  [MENU_INTEGRATIONS]: BOT,
  [MENU_CHANGE_LOGS]: BOT,
  [MENU_GENERATIVE_AI]: BOT,

  [MENU_OVERVIEW]: REPORTS,
  [MENU_TEAM_PERFORMANCE]: REPORTS,
  [MENU_WISE_AGENT]: REPORTS,
  [MENU_FEEDBACK]: REPORTS,

  [PROFILE]: SETTINGS,
  [PROJECT_SETTINGS]: SETTINGS,
  [USERS_MANAGEMENT]: SETTINGS,
  [DEPARTMENTS_MANAGEMENT]: SETTINGS,
};

const CRM = ({ setFiltersAction, setMainMenu, setBotTab, setReportsTab, setSubMenu, ...props }) => {
  const menuToSelector = {
    [BOT]: setBotTab,
    [REPORTS]: setReportsTab,
    [SETTINGS]: setSubMenu,
  };

  const { path } = useResolvedPath('').pathname;
  const { id: projectId, menu, conversation } = useParams();
  let userId, conversationId;
  if (conversation) {
    [userId, conversationId] = conversation.split('_');
  }
  const [filters, setFilters] = useState({});
  const [ioCRMSocket, setIoCRMSocket] = useState(null);
  const [messageData, setMessageData] = useState(null);
  const [assignData, setAssignData] = useState(null);

  const [conversationStatusUpdateData, setConversationStatusUpdateData] = useState(null);
  const previousConversationStatus = usePrevious(props.currentConversation?.conversation_status);
  const [newConversationData, setNewConversationData] = useState(null);

  const fetchFilters = useCallback(() => {
    Axios.get(GET_FILTERS_ENDPOINT, {
      params: { projectId },
    })
      .then(({ data }) => {
        setFilters(data);
        setFiltersAction(data);
      })
      .catch(console.log);
  }, [projectId, setFiltersAction]);

  useComponentDidMount(
    () => {
      const mainMenu = subMenuToMenu[menu] || menu;
      setMainMenu(mainMenu);
      const subMenuSelector = menuToSelector[mainMenu];
      if (menu === SETTINGS) {
        setSubMenu(PROFILE);
      } else {
        subMenuSelector && subMenuSelector(menu);
      }

      props.setInboxFolder({
        submenu: 'External',
        file: { title: languageMap.you, value: 'you' },
      });

      const getCRMData = async () => {
        await props.setAgents(projectId);

        const firstConversationData = await props.setConversations({
          folder: 'you',
          conversation_status: props.conversationStatusFilter,
          project_id: projectId,
        });

        let currentUserId = userId || firstConversationData?.payload?.conversations[0]?.user_id;
        let currentConversationId =
          conversationId || firstConversationData?.payload?.conversations[0]?.conversation_id;

        await props.setDepartments(projectId);

        if (!props.isMobile) {
          if (currentUserId !== undefined && projectId !== undefined) {
            const chatHistory = await props.setChatHistory({
              user_id: currentUserId,
              project_id: projectId,
              conversation_id: currentConversationId,
            });
            const conversation = chatHistory?.payload?.conversation_data;

            props.setCurrentConversation(conversation);
          }
        }
      };

      const startCRMSocket = async () => {
        const token = await generateAuthToken();
        await props.setToken(token);
        const ioCRMSocket = new ioCRM(token, projectId);
        setIoCRMSocket(ioCRMSocket);
      };

      getCRMData();
      startCRMSocket();
    },
    () => setMainMenu('inbox')
  );

  useEffect(() => {
    setConversationStatusUpdateData(null);
  }, [props.currentConversation]);

  useEffect(() => {
    const messageListener = (data) => {
      console.log('MESSAGE:', data);
      if (data.message.message_subtype === 'channel transfer') {
        refreshConversationChannel(data.conversation_meta.conversation_id);
      }
      setMessageData(data);
    };

    if (ioCRMSocket) {
      ioCRMSocket.listenFor('message', messageListener);
    }

    return () => {
      if (ioCRMSocket) {
        ioCRMSocket.removeListener('message', messageListener);
      }
    };
  }, [ioCRMSocket, setIoCRMSocket, setMessageData]);

  useEffect(() => {
    const assignListener = (data) => {
      console.log('ASSIGN: ', data);
      setAssignData(data);
    };

    if (ioCRMSocket) {
      ioCRMSocket.listenFor('assign', assignListener);
    }

    return () => {
      if (ioCRMSocket) {
        ioCRMSocket.removeListener('assign', assignListener);
      }
    };
  }, [ioCRMSocket, setIoCRMSocket, setAssignData]);

  useEffect(() => {
    const statusListener = (data) => {
      setConversationStatusUpdateData(data);
    };

    if (ioCRMSocket) {
      ioCRMSocket.listenFor('status_update', statusListener);
    }

    return () => {
      if (ioCRMSocket) {
        ioCRMSocket.removeListener('status_update', statusListener);
      }
    };
  }, [ioCRMSocket, setIoCRMSocket, setConversationStatusUpdateData]);

  useEffect(() => {
    const newConversationListener = (data) => {
      setNewConversationData(data);
    };

    if (ioCRMSocket) {
      ioCRMSocket.listenFor('new_conversation', newConversationListener);
    }

    return () => {
      if (ioCRMSocket) {
        ioCRMSocket.removeListener('new_conversation', newConversationListener);
      }
    };
  }, [ioCRMSocket, setIoCRMSocket, setNewConversationData]);

  useEffect(() => {
    if (messageData) {
      processWSEvents.onMessage(messageData, { ...props, projectId });
    }
  }, [messageData]);

  useEffect(() => {
    if (assignData) {
      processWSEvents.onAssign(assignData, props);
    }
  }, [assignData]);

  useEffect(() => {
    if (conversationStatusUpdateData && previousConversationStatus != undefined) {
      processWSEvents.onConversationStatusUpdate(
        { ...conversationStatusUpdateData, previousConversationStatus },
        props
      );
    }
  }, [conversationStatusUpdateData, previousConversationStatus]);

  useEffect(() => {
    if (newConversationData) {
      processWSEvents.onNewConversation(newConversationData, props);
    }
  }, [newConversationData]);

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);
  return (
    <div className="CRMContainer">
      <div className="CRM">
        <CrmFiltersContext.Provider value={filters}>
          <CRMDashboard />
        </CrmFiltersContext.Provider>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  mainMenu: selectMainMenu(state),
  inboxFolder: selectInboxFolder(state),
  conversationStatusFilter: selectConversationsStatusFilter(state),
  conversations: selectConversations(state),
  currentConversation: selectCurrentConversation(state),
  currentUser: selectCurrentUser(state),
  myDepartmentsIDs: selectDepartmentsIDs(state),
  isMobile: selectIsMobile(state),
});

const mapDispatchToProps = {
  setToken,
  setMainMenu,
  setSubMenu,
  setInboxFolder,
  setFiltersAction: setFilters,
  setAgents: getAgents,
  setDepartments: getDepartments,
  setConversations: getConversations,
  setChatHistory: getChatHistory,
  updateLastMessage,
  updateMessageTimestamp,
  setConversationReadStatus,
  setNewConversation,
  removeConversation,
  setToConversationHistory,
  incrementYou,
  decrementYou,
  addToYouUnread,
  removeFromYouUnread,
  incrementUnassigned,
  decrementUnassigned,
  addToUnassignedUnread,
  incrementAll,
  decrementAll,
  addToExternalAllUnread,
  incrementInternalAll,
  decrementInternalAll,
  addToInternalAllUnread,
  updateConversationInList,
  removeFromUnassignedUnread,
  removeFromExternalAllUnread,
  removeFromInternalAllUnread,
  setCurrentConversation,
  setBotTab,
  setReportsTab,
  refreshConversationChannel,
};

export default connect(mapStateToProps, mapDispatchToProps)(CRM);
