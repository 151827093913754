import { io } from 'socket.io-client';

import { crmEndpoint, crmSocketParams } from 'utils/constants';

export default class ioCRM {
  constructor(token, projectId) {
    let params = { auth: { token, project_id: projectId }, ...crmSocketParams };
    params.path = '/crm';
    params.cors = {
      origin: crmEndpoint,
      methods: ['GET', 'POST'],
    };
    this.socket = io(crmEndpoint, params);

    this.socket.on('connect', async () => {
      this.socket.emit('auth');
    });

    this.socket.on('authenticated', (departments) => {
      console.log('CRM socket authenticated');
      this.socket.emit('join', 'CRM Server connected to Client');
    });
  }

  receiveMessage = () => {
    this.socket.on('messages', (data) => {
      console.log(data);
    });
  };

  sendMessage = (message) => {
    this.socket.emit('messages', message);
  };

  sendTo = (customEvent, data) => {
    this.socket.emit(customEvent, data);
  };

  listenFor = (customEvent, cb) => {
    this.socket.on(customEvent, (data) => {
      cb(data);
    });
  };

  removeListener = (customEvent, cb) => {
    this.socket.off(customEvent, cb);
  };
}
