import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import languageMap from '../../Language/Language';
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/system';
import mainTheme from '../../mainTheme';

const PreventedButton = ({ text, onClick, style, color }) => (
  <Button
    style={style}
    color={color}
    variant="contained"
    onPointerDown={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
    onPointerUp={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick && onClick();
    }}
  >
    {text}
  </Button>
);

const DeleteConfirmationModal = (props) => {
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    props.onDelete && props.onDelete();
    setOpen(false);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(mainTheme)}>
        {' '}
        <Modal
          size="mini"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          trigger={props.trigger}
        >
          <Modal.Header>{languageMap.confirmDelete}</Modal.Header>
          <Modal.Content>
            <p>{languageMap.confirmDeletePrompt}</p>
          </Modal.Content>

          <Modal.Actions>
            <PreventedButton color="primary" onClick={handleDelete} text={languageMap.yes} />
            <PreventedButton
              color="secondary"
              style={{ marginLeft: 10 }}
              onClick={() => setOpen(false)}
              text={languageMap.no}
            />
          </Modal.Actions>
        </Modal>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default DeleteConfirmationModal;
