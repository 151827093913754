import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import { ANNOTATION_CLUSTERS_ENDPOINT } from 'utils/constants';
import Loader from 'components/common/Loader';
import {produce} from 'immer';
import _ from 'lodash';

const Clusters = (props) => {
  const { projectId, contentType } = props;
  const [clusters, setClusters] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchClusters = useCallback(() => {
    setLoading(true);
    Axios.get(ANNOTATION_CLUSTERS_ENDPOINT, {
      params: {
        project_id: projectId,
        content_type: contentType,
      },
    })
      .then(({ data }) => {
        setClusters(data);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    fetchClusters();
  }, [fetchClusters]);

  const setShowAll = (cluster_id, show_all) => {
    const x = produce(clusters, (draft) => {
      draft[cluster_id].show_all = show_all;
    });
    setClusters(x);
  };

  let utterances = Object.keys(clusters).map((key) => {
    let cluster = clusters[key];
    let utterances = _.cloneDeep(cluster.annotations);
    let showButtonText = 'show less';
    let title = utterances[0];
    utterances.shift();

    if (!cluster.show_all) {
      showButtonText = 'show all ' + utterances.length;
      utterances = utterances.slice(1, 10);
    }

    return (
      <div
        style={{
          borderBottom: '1px solid silver',
          boxShadow: '#aaaaaa 0px 0px 5px',
          borderRadius: 2,
          marginBottom: 20,
          padding: 5,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <span style={{ color: 'red', fontWeight: 'bold' }}> {title}</span>
        {utterances.map((utterance) => {
          return <span>{utterance}</span>;
        })}
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            setShowAll(key, !cluster.show_all);
          }}
        >
          {showButtonText}
        </a>
      </div>
    );
  });

  return (
    <>
      {loading && <Loader className="Annotations_Loader" />}
      <div style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', padding: 10 }}>
        {utterances}
      </div>
    </>
  );
};

export default Clusters;
