import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useSound from 'use-sound';

import MessagesMenu from './components/MessagesMenu';
import SubmenuReportsContainer from 'containers/SubmenuReportsContainer';
import SubmenuProjectContainer from 'containers/SubmenuProjectContainer';
import languageMap from 'Language/Language';

import ProjectsIcon from 'assets/menuIcons/Projects';
import SettingsIcon from 'assets/menuIcons/Settings';
import LogoutIcon from 'assets/menuIcons/Logout';
import SmallLogo from 'assets/svg/SmallNewLogo';
import { Popup } from 'semantic-ui-react';
import { Logo } from 'assets/logo/Logo';
import { DOCUMENT_TITLE } from '../../index';

import { hasAccess } from 'services/userPermissions';

import {
  selectConversations,
  selectConversationsStatusFilter,
  selectCurrentConversation,
  selectFolderTotals,
  selectInboxFolder,
  selectIsMobile,
  selectMainMenu,
  selectSearch,
  selectUnreadConversations,
  selectCurrentUser,
} from 'store/crm/selectors';

import {
  resetSearch,
  setInboxFolder,
  setMainMenu,
  setSearchStatus,
  setStartFromConversationIndex,
  setSubMenu,
} from 'store/crm/actions';

import './MenuComponent.scss';
import { useNavigate } from 'react-router-dom';
import { MAIN_COLOR } from '../../mainTheme';
import getAssetPath from '../../utils/getAssetPath';
import { SETTINGS } from '../../utils/constants';

let titleInterval;
let arrIndex = -1;

const MenuComponent = ({
  inboxFolder,
  unreadConversations,
  unreadConversations: { you, unassigned, internal_all },
  conversations,
  currentConversation,
  structure: { inboxSubMenus },
  conversationsStatusFilter,
  isMobile,
  resetSearchAction,
  setSearchStatusAction,
  setStartFromConversationIndexAction,
  setInboxFolderAction,
  setMenu,
  setSubMenu,
  mainMenu,
  folderTotals,
  permissions,
  collapsed,
  currentUser,
  ...props
}) => {
  const hasPermissions =
    typeof permissions === 'object' ? Object.keys(permissions).length > 0 : false;

  // This creates useEffect warning (no-op) , we are ok with it, the library does internal cleanup
  const [playSonorNotification] = useSound(getAssetPath('notification_sound.wav'));

  const [previousYou, setPreviousYou] = useState(you);
  const [previousUnassigned, setPreviousUnassigned] = useState(unassigned);
  const [previousInternalAll, setPreviousInternalAll] = useState(internal_all);
  const [count, setCount] = useState(0);
  const [msgFolderListener, setMsgFolderListener] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('focus', documentOnFocus);

    documentOnFocus();

    return () => {
      window.removeEventListener('focus', documentOnFocus);
      clearInterval(titleInterval);
    };
  }, []);

  useEffect(() => {
    if (conversationsStatusFilter === 1) {
      if (playSonorNotification && count > 1) {
        if (you.length !== previousYou.length && you.length > 0) {
          if (you.length > previousYou.length) {
            playSonorNotification();
            newMessageTabNotification('you');
          }
        } else if (unassigned.length !== previousUnassigned.length && unassigned.length > 0) {
          if (unassigned.length > previousUnassigned.length) {
            playSonorNotification();
            newMessageTabNotification('unassigned');
          }
        } else if (internal_all.length !== previousInternalAll.length && internal_all.length > 0) {
          if (internal_all.length > previousInternalAll.length) {
            playSonorNotification();
            newMessageTabNotification('internal_all');
          }
        }
      }
    }
    setPreviousYou(you);
    setPreviousUnassigned(unassigned);
    setPreviousInternalAll(internal_all);
    setCount(count + 1);
  }, [you.length, unassigned.length, internal_all.length, unreadConversations]);

  useEffect(() => {
    return () => {
      setInboxFolderAction({
        submenu: 'External',
        file: { title: languageMap.you, value: 'you' },
      });
    };
  }, []);

  const shouldRenderMenu = (menu) => {
    if (props.projectId && hasPermissions) {
      return hasAccess(props.projectId, permissions, menu);
    }
    return false;
  };

  const onClick = (menu) => () => {
    navigate('/project/' + props.projectId + '/crm/' + menu);
    setMenu(menu);
    if (menu === SETTINGS) {
      setSubMenu('profile');
    } else {
      setSubMenu('');
    }
  };

  const sections = [];

  if (hasAccess(props.projectId, permissions, 'bot')) {
    sections.push(
      { title: languageMap.qna, value: 'QnA' },
      { title: languageMap.flows, items: [], value: 'Flows' },
      { title: languageMap.collections, value: 'Collections' },
      { title: languageMap.learning, value: 'Learning' },
      { title: languageMap.changeLogs, value: 'Change logs' }
    );
  }

  if (hasAccess(props.projectId, permissions, 'integrations')) {
    sections.push({ title: languageMap.integrations, value: 'Integrations' });
  }
  if (
    currentUser?.features[props.projectId]?.has_genai &&
    hasAccess(props.projectId, permissions, 'generativeAi')
  ) {
    sections.push({ title: languageMap.generativeAI, value: 'Generative AI' });
  }

  const reportsSections = [
    { title: languageMap.overview, items: [], value: 'Overview' },
    { title: languageMap.wiseAgent, value: 'Wise Agent' },
    { title: languageMap.teamPerformance, value: 'Team Performance' },
    { title: languageMap.feedback, value: 'Feedback' },
  ];

  const unselectedColor = '#A3AED0';
  const selectedColor = '#0530F9';

  const settingsMenuIcon = (
    <SettingsIcon fill={mainMenu === SETTINGS ? selectedColor : unselectedColor} />
  );

  let globalSections = [
    {
      title: languageMap.projects,
      value: 'projects',
      icon: <ProjectsIcon />,
      onClick: () => () => {
        setSubMenu('');
        navigate('/projects');
      },
    },
    {
      title: languageMap.profileLogout,
      value: 'logout',
      icon: <LogoutIcon />,
      onClick: () => () => {
        setSubMenu('');
        navigate('/logout');
      },
    },
  ];
  if (!isMobile) {
    globalSections.unshift({
      title: languageMap.settings,
      value: 'settingsMenu',
      icon: settingsMenuIcon,
      onClick,
    });
  }

  const changeTitle = (titleArray) => {
    ++arrIndex;
    if (arrIndex >= titleArray.length) {
      arrIndex = 0;
    }
    document.title = titleArray[arrIndex];
  };

  const startTitleInterval = (titleArray) => {
    titleInterval = setInterval(() => {
      changeTitle(titleArray);
    }, 2000);
  };

  const newMessageTabNotification = (msgFolder) => {
    arrIndex = -1;
    clearInterval(titleInterval);
    let msgFolderCheck = '';
    if (document.hidden) {
      if (msgFolderListener !== msgFolder) {
        msgFolderCheck = `${msgFolder}${msgFolderListener}`;
        setMsgFolderListener(msgFolderCheck);
      }
      switch (msgFolderCheck) {
        case 'youunassigned' || 'unassignedyou':
          startTitleInterval([
            languageMap.newMessage,
            `${folderTotals.unassigned} ${languageMap.unassigned}`,
            DOCUMENT_TITLE,
          ]);
          break;
        case 'you':
          startTitleInterval([languageMap.newMessage, DOCUMENT_TITLE]);
          break;
        case 'unassigned':
          startTitleInterval([
            `${folderTotals.unassigned} ${languageMap.unassigned}`,
            DOCUMENT_TITLE,
          ]);
          break;
        case 'internal_all':
          startTitleInterval([
            `${folderTotals.internal_all} ${languageMap.newInternal}`,
            DOCUMENT_TITLE,
          ]);
          break;
        default:
          document.title = DOCUMENT_TITLE;
      }
    }
  };

  const documentOnFocus = () => {
    clearInterval(titleInterval);
    setMsgFolderListener('');
    arrIndex = -1;
    document.title = DOCUMENT_TITLE;
  };

  const isSelected = (menu) => {
    return mainMenu === menu;
  };

  const getClasses = (menu) => {
    if (mainMenu === menu) {
      return 'submenu-section selected';
    }
    return 'submenu-section';
  };

  let containerWidth = collapsed ? 81 : 271;
  let rootStyle = { width: containerWidth };
  if (collapsed) {
    rootStyle = {
      paddingLeft: 21,
      paddingRight: 21,
      paddingBottom: 10,
    };
  }

  let rootClass = collapsed ? 'MenuComponent MenuComponentCollapsed' : 'MenuComponent';
  let isTectu = DOCUMENT_TITLE === 'Tectu';

  return (
    <div className={rootClass} style={rootStyle}>
      {!collapsed && (
        <div className="logo-container">
          <Logo />
        </div>
      )}

      {collapsed && <div className="logo-container">{isTectu ? <div /> : <SmallLogo />}</div>}

      {shouldRenderMenu('inbox') && (
        <>
          {!collapsed && (
            <div className="section-title top-section-title">{languageMap.messages}</div>
          )}
          <MessagesMenu menuItems={inboxSubMenus} inboxFolder={inboxFolder} collapsed={collapsed} />
          {collapsed && <div style={{ marginTop: 30 }} />}
        </>
      )}

      {Boolean(sections.length > 0) && (
        <>
          {!isMobile && (
            <SubmenuProjectContainer
              headerTitle={languageMap.botConfiguration}
              sections={sections}
              collapsed={collapsed}
            />
          )}
          {collapsed && <div style={{ marginTop: 30 }} />}
        </>
      )}

      {shouldRenderMenu('reports') && !isMobile && (
        <SubmenuReportsContainer
          headerTitle={languageMap.reports}
          sections={reportsSections}
          collapsed={collapsed}
        />
      )}

      <div style={{ marginTop: 30 }}>
        {globalSections.map(
          (section) =>
            (section.value !== 'settings' || shouldRenderMenu('settings')) && (
              <Popup
                key={`${section.value}_section`}
                style={{ color: MAIN_COLOR }}
                trigger={
                  <div key={`${section.value}_section`} className="MenuReportsComponent">
                    <div
                      className={getClasses(section.value)}
                      onClick={section.onClick(section.value)}
                    >
                      {section.icon}
                      {!collapsed && <div className="dm-sans-500 title">{section.title}</div>}
                      {!collapsed && isSelected(section.value) && <div className="selected-bar" />}
                    </div>
                  </div>
                }
                content={section.title}
                disabled={!collapsed}
                size="mini"
                position="right center"
              />
            )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  inboxFolder: selectInboxFolder(state),
  folderTotals: selectFolderTotals(state),
  unreadConversations: selectUnreadConversations(state),
  conversations: selectConversations(state),
  currentConversation: selectCurrentConversation(state),
  search: selectSearch(state),
  conversationsStatusFilter: selectConversationsStatusFilter(state),
  isMobile: selectIsMobile(state),
  mainMenu: selectMainMenu(state),
  currentUser: selectCurrentUser(state),
});

const mapDispatchToProps = {
  setSearchStatusAction: setSearchStatus,
  resetSearchAction: resetSearch,
  setStartFromConversationIndexAction: setStartFromConversationIndex,
  setInboxFolderAction: setInboxFolder,
  setMenu: setMainMenu,
  setSubMenu: setSubMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuComponent);
